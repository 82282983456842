.register {
    margin: 0;
    margin-inline: 0;
}

.lockScreenBack {
    position: absolute;
    opacity: 0.7;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: var(--ion-background-color);
}
.lockScreenFront {
    position: absolute;
    display: flex;
    z-index: 1001;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* Add the blur effect */
    backdrop-filter: blur(5px);
}

.title {
    padding-inline: 1em;
}

.successDialog {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ion-icon {
        font-size: 6em;
        color: var(--ion-color-success);
    }
}

.form {
    display: flex;
    height: 100%;
    flex-direction: column;

    ion-icon {
        color: var(--ion-color-primary);
        --ionicon-stroke-width: 3em;
    }
    .upperPart {
        display: flex;
        flex-direction: column;
        height: 50%;
        /* text-align: center; */
        justify-content: space-around;

        .logoWrapper {
            display: flex;
            align-items: center;
            align-self: center;
            img {
                width: 6em;
                align-self: center;
            }
        }
    }

    .eventDetails {
        padding: 1em;
        p {
            display: flex;
            align-items: center;
            ion-icon {
                margin-right: 0.5em;
            }
        }
    }
}
